import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

const BLACKLIST_AGENTS = ['FBAV', 'FBAN'];

/**
 * TODO rkara-ssr-defect
 * This file does not work appropriately while built in SSR.
 */

@Injectable({
  providedIn: 'root',
})
export class SrvSafeWindowService {
  get hasWindow() {
    return isPlatformBrowser(this.platformId);
  }

  get innerWidth() {
    if (this.hasWindow) {
      return window.innerWidth;
    }
    return undefined;
  }


  get innerHeight() {
    if (this.hasWindow) {
      return window.innerHeight;
    }
    return undefined;
  }

  get navigator() {
    if (this.hasWindow) {
      return window.navigator;
    }
    return {
      onLine: true,
      permissions: {} as {[key:string]:any},
    };
  }

  get location() {
    if (typeof(navigator) === 'undefined') {
      return undefined;
    }
    const ua = navigator?.userAgent ?? '';
    // var s = (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    if ((
      ua.indexOf('FBAN') > -1) ||
      (ua.indexOf('FBAV') > -1) ||
      !document ||
      !window) {
      return {
        href: window.location.href || document?.location.href,
        protocol: 'https://',
        host: 'app.gametimehero.com',
        origin: '',
      };
    }
    return document?.location || window.location;
  }

  get history() {
    if (this.hasWindow) {
      return window.history;
    }
    return undefined;
  }

  get historyUser() {
    if (this.hasWindow) {
      if (!window.history.state || !window.history.state.user) return null;
      return window.history.state.user.model;
    }

    return undefined;
  }

  get isLocalhost() {
    if (!this.hasWindow) return false;
    // Check if the protocol is 'file:' (local file system) or 'http:' or 'https:' (localhost)
    return (
      window.location.protocol === 'file:' ||
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'
    );
  }

  get blacklisted() {
    if (this.hasWindow) {
      const userAgent = window.navigator.userAgent;
      const isUnsafe = BLACKLIST_AGENTS.some((browser) => userAgent.includes(browser));
      if (isUnsafe) {
        return true;
      }
    }
    return false;
  }

  getComputedStyle(element: Element): CSSStyleDeclaration {
    if (!this.hasWindow) return {} as CSSStyleDeclaration;
    else return window.getComputedStyle(element);
  }

  constructor(
    @Inject(PLATFORM_ID)
    private platformId: any,
  ) { }

  scrollTo(x: number, y: number) {
    if (!this.hasWindow) return;
    window.scrollTo(x, y);
  }

  navigate(path: string) {
    if (this.hasWindow && typeof(window.location) !== 'undefined') {
      window.location.href = path;
      return;
    }
  }

  open(path: string, target?: string) {
    if (this.hasWindow) {
      window.open(path, target);
      return;
    }
  }

  setProp(key: string, value: any) {
    if (this.hasWindow) {
      (window as any) = value;
      return;
    }
  }

  getProp(key: string) {
    if (this.hasWindow) {
      return (window as any)[key];
    }
    return undefined;
  }

  createObjectURL(obj: Blob | MediaSource) {
    if (this.hasWindow) {
      return window.URL.createObjectURL(obj);
    }
    return undefined;
  }

  reload() {
    if (this.hasWindow) {
      window.location.reload();
      return;
    }
  }
}
